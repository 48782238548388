import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as React from "react"

import { toSentenceCase } from "@iyk/string"
import { Link, useNavigate } from "@remix-run/react"
import { CartTrigger } from "../../components/shop/cart-trigger.tsx"
import { IYK_LOGO_URL } from "../../lib/constants.ts"
import { useLoginDialog } from "../../lib/ui/login-dialog-provider.tsx"
import { useEventListener } from "../hooks/use-event-listener.ts"
import { useIykUser } from "../hooks/use-iyk-user.ts"
import { useLang } from "../lang/use-lang.ts"
import { extendRef } from "../utils/ref.ts"
import { AccountDropdown } from "./account-dropdown.tsx"

const SCROLL_THRESHOLD = 20

export type HeaderVariant = "sticky" | "fixed"

// #region Types

// Type for the Header component's props
type HeaderProps = {
  /**
   * Determines the header's position style.
   * Can be "sticky" or "fixed".
   */
  variant?: HeaderVariant

  /**
   * Additional class names for styling the header.
   */
  className?: string

  /**
   * Options for customizing the header's behavior
   * or hide elements like the cart or login button
   */
  options?: HeaderOptions

  /**
   * Navigation properties for the header affecting the left side of the header.
   */
  mainNavigation?: NavigationProps
} & React.ComponentProps<"header">

// Options for customizing the header's behavior
type HeaderOptions = {
  /**
   * If true, the cart icon is hidden.
   */
  hideCart?: boolean

  /**
   * If true, the login action is hidden.
   */
  hiddenLoginAction?: boolean

  /**
   * The scroll threshold for hiding the header.
   */
  scrollThreshold?: number
}

/**
 * NavigationProps represents the properties for different types of navigation.
 * The type adapts based on the provided `kind` of navigation.
 */
type NavigationProps = NavigationWithLogoProps | NavigationWithBackProps | NavigationWithNoneProps

type NavigationWithLogoProps = {
  /**
   * Use when you want to display a logo.
   */
  kind: "logo"
  /**
   * Source URL for the logo image.
   */
  src?: string
  /**
   * The path to navigate to.
   */
  navigateTo?: string
}

type NavigationWithBackProps = {
  /**
   * Use when you want to display a back button.
   */
  kind: "back"
  /**
   * The path to navigate to.
   */
  navigateTo: string
}

type NavigationWithNoneProps = {
  /**
   * Use when you want to display nothing.
   */
  kind: "none"
}

// #endregion

// #region Header

export const Header = React.forwardRef<HTMLElement, HeaderProps>(
  (
    { className, mainNavigation = { kind: "logo" }, options, variant = "sticky", ...props },
    ref,
  ) => {
    const { headerRef, isHidden } = useScrollVisibility(
      options?.scrollThreshold ?? SCROLL_THRESHOLD,
    )

    return (
      <header
        ref={extendRef(headerRef, ref)}
        className={classForHeader({ isHidden, variant, className })}
        {...props}
      >
        <ManiNavigation {...{ mainNavigation }} />
        <div className="flex items-center gap-3">
          <HeaderAuthController hiddenLoginAction={options?.hiddenLoginAction} />
          {!options?.hideCart && <CartTrigger />}
        </div>
      </header>
    )
  },
)

Header.displayName = "Header"

const ManiNavigation = ({ mainNavigation }: { mainNavigation: NavigationProps }) => {
  const navigate = useNavigate()

  const { kind } = mainNavigation

  if (kind === "none") {
    return <span />
  }

  const { navigateTo = "" } = mainNavigation

  if (kind === "logo") {
    const { src } = mainNavigation
    const logoSrc = src || IYK_LOGO_URL
    return (
      <Link to={navigateTo}>
        <HeaderLogo src={logoSrc} variant={src ? "rounded" : "circle"} />
      </Link>
    )
  }

  if (kind === "back") {
    return navigateTo ? (
      <Link to={navigateTo} className={UI.classForButton({ kind: "shaded", size: "sm" })}>
        <Icon.ArrowLeft />
      </Link>
    ) : (
      <UI.Button type="button" kind="shaded" size="sm" onClick={() => navigate(-1)}>
        <Icon.ArrowLeft />
      </UI.Button>
    )
  }
}

export const classForHeader = UI.cva(
  [
    "text-gray-12",
    "z-header top-0 left-0 w-full h-[var(--header-height)] p-4",
    "flex item-center justify-between",
    "transition-[top] duration-300 ease-in-out",
  ],
  {
    variants: {
      isHidden: {
        // We do this because the -top-[var(--header-height)] is not working
        true: "top-[calc(var(--header-height)*-1)]",
      },
      variant: {
        sticky: "sticky bg-background",
        fixed: "fixed bg-transparent",
      },
    },
  },
)

// #endregion

// #region HeaderAuthController

const HeaderAuthController = ({ hiddenLoginAction }: { hiddenLoginAction?: boolean }) => {
  const { isSignedIn } = useIykUser()
  const { showLoginDialog } = useLoginDialog()

  if (isSignedIn) return <AccountDropdown />
  if (hiddenLoginAction) return null

  return (
    <button type="button" onClick={() => showLoginDialog()}>
      <Icon.Profile className="size-6" />
    </button>
  )
}

// #endregion

// #region HeaderLogo

export const HeaderLogo = ({ className, ...props }: UI.AvatarProps) => {
  const lang = useLang()

  return (
    <UI.Avatar
      className={classForHeaderLogo({ className })}
      alt={toSentenceCase(lang.terms.POWERED_BY) + " IYK"}
      {...props}
    />
  )
}

const classForHeaderLogo = UI.cva(["size-7"])

// #endregion

// #region Hook

export const useScrollVisibility = (threshold: number = SCROLL_THRESHOLD) => {
  const [isHidden, setIsHidden] = React.useState(false)
  const lastScrollTopPosition = React.useRef(0)
  const headerRef = React.useRef<HTMLElement | null>(null)
  const navbarHeight = React.useRef(headerRef.current?.offsetHeight || 0)

  useEventListener(
    "scroll",
    React.useCallback(() => {
      const currentScrollTop = window.scrollY
      const lastScrollTop = lastScrollTopPosition.current

      // Ignore small scroll changes
      if (Math.abs(lastScrollTop - currentScrollTop) <= threshold) return

      setIsHidden(currentScrollTop > lastScrollTop && currentScrollTop > navbarHeight.current)
      lastScrollTopPosition.current = currentScrollTop
    }, [threshold]),
    undefined,
    { passive: true },
  )

  return { isHidden, headerRef }
}

// #endregion
